<template>
  <div class="btn-set">
    <div class="col-12">
      <button type="button" @click="toggleModal" class="btn btn-shadow" :class="btnStatusClass">{{title}} <i class="fal fa-plus"></i></button>
    </div>
    <div class="col-12 my-2">
      <a href="#" @click.prevent="$emit('toggle-quote-only', !quoteOnly)" :class="[quoteOnly ? 'green-text' : '']">
        <i :class="[ourQuoteOnly ? 'fa-check-square' : 'fa-square', 'fal']">&nbsp;</i> Get only a Quote
      </a>
    </div>
    <h6 v-if="isFlexible">{{flexibleDescription}}</h6>
  </div>
</template>
<script>

export default {
  props: {
    btnClass: {
      type: String,
      default: 'btn-default'
    },
    btnTitle: {
      type: String,
      default: 'Book a date & time'
    },
    flexibleDescription: {
      type: String,
      default: null
    },
    quoteOnly: {
      type: Boolean,
      required: true
    },
    startPicked: {
      type: String
    },
  },
  computed: {
    active() {
      return (this.startPicked || this.isFlexible) && !this.ourQuoteOnly
    },
    btnStatusClass() {
      var cls = this.btnClass
      if (this.active) {
        cls = cls + ' active'
      }
      return cls
    },
    isFlexible() {
      return !!this.flexibleDescription
    },
    title() {
      if (this.isFlexible) {
        return 'Flexible'
      }
      if (this.startPicked) {
        return this.startPicked
      }
      return this.btnTitle
    },
    ourQuoteOnly: {
      set(v) {
        this.$emit('toggle-quote-only', v)
      },
      get() {
        return this.quoteOnly
      }
    }
  },
  methods: {
    toggleModal() {
      this.$emit('toggle-modal')
      this.ourQuoteOnly = false
    },
    toggleQuoteOnly() {
      this.ourQuoteOnly = !this.ourQuoteOnly
    }
  }

}
</script>
