<template>
  <form @submit.prevent="$emit('save')" class="pb-xs-5">
    <input type="hidden" name="reservation[extras]" :value="JSON.stringify(this.changedExtras)">
    <div class="row mt-3">
      <extras
        context="visit-extras"
        wrapper-class=''
        title=''
        :allowed-extra-ids="allowedExtraIds"
        :can-remove-extras="canRemoveExtras"
        :edit-mode="true"
        :show-visit-warning="false"
        :property-id="propertyId"
        :saved-extras="savedExtras"
        :quantities="changedExtras"
        @update="onExtraUpdate"
        @update:picked-extras="$emit('update:picked-extras', $event)"
        ></extras>
    </div>
    <h4 class="text-center mt-4">
      <label class="checkbox-set">
        Use these extras for future bookings
        <input type="checkbox" v-model="ownSaveExtras"><span class="checkmark"></span>
      </label>
    </h4>
    <button type="submit" class="btn btn-primary main-btn mt-4">Next</button>
  </form>
</template>
<script>
import Vue from 'vue'
import Extras from '@/shared/extras.vue'
import UpdateableExtras from '@/shared/updateable_extras.vue'
export default {
  extends: UpdateableExtras,
  props: {
    canChangeExtras: {
      type: Boolean,
      default: true
    },
    propertyId: {
      type: Number,
      required: true
    },
    saveExtras: {
      type: Boolean
    }
  },
  computed: {
    ownSaveExtras: {
      get() {
        return this.saveExtras
      },
      set(v) {
        this.$emit('update:save-extras', v)
      }
    }
  }
}
</script>
