<template>
  <base-modal dom-id="start_picker_modal" :show="showModal" sizeClass="modal-md" classes="calendar-modal" headerClass="modal-header-booking" bodyClass="modal-body white-theme" :hide-footer="true">
    <div slot="content">
      <h4 class="font-16 gray-text text-center">Select the booking date &amp; time</h4>
      <hr>
      <start-picker @start-picked="onStartPicked" :datetime="start" :size="size" :status-field="statusField" :zip="zip" :zip-field="zipField" :start-field="startField" :min-date="minDate" :quote-only="quoteOnly" :show="show" :location-id="locationId"></start-picker>
      <template v-if="allowFlexible">
        <hr>
        <a href="#" @click.prevent="showFlexible"><p class="blue-text text-center">Can not find the right time and date?</p></a>
      </template>
    </div>
  </base-modal>
</template>
<script>
import { dateToAppFormat } from '@/utils'
import StartPicker from './start_picker.vue'
import BaseModal from '@/modals/base_modal.vue'

export default {
  components: { BaseModal, StartPicker },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    allowFlexible: {
      type: Boolean,
      default: true
    },
    datetime: {
      type: String
    },
    minDate: {
      type: String
    },
    startField: {
      type: String,
      required: true
    },
    statusField: {
      type: String,
      required: true
    },
    size: {
      type: String
    },
    locationId: {
      type: Number
    },
    zip: {
      type: String
    },
    zipField: {
      type: String
    },
    quoteOnly: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showModal: this.show,
      showPicker: true,
      start: this.datetime
    }
  },
  methods: {
    onStartPicked(formattedStart, start, status) {
      var start2Send, status2Send
      if (start) {
        start2Send = start
      } else {
        start2Send = this.start
      }
      if (status) {
        status2Send = status
      } else {
        status2Send = $(this.statusField).value
      }
      this.$emit('start-picked', formattedStart, start2Send, status2Send)
    },
    showFlexible() {
      this.showModal = false
      this.$emit('show-flexible')
    },
  },
  mounted() {
    $('#start_picker_modal').on('hidden.bs.modal', (e) => {
      this.$emit('update:show-modal', false)
    })
    this.$emit('booking-init')
  },
  watch: {
    show(newValue, oldValue) {
      this.showModal = newValue
    }
  }
}
</script>
